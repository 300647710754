import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';
import store from './redux/store';
import BrandBattle from './BrandBattle';
import AdminLogin from './components/admin/AdminLogin';
import AdminPanel from './components/admin/AdminPanel';
import './styles/globals.css';
import Auth from './components/Auth';
import telegramService from './services/telegramService';
import LoadingScreen from './components/LoadingScreen';
import DailyRewards from './components/DailyRewards';
import { dailyRewardsService } from './services/dailyRewardsService';

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [appReady, setAppReady] = useState(false);
  const [showDailyReward, setShowDailyReward] = useState(false);
  const [dailyRewardStatus, setDailyRewardStatus] = useState(null);

  // В начале файла после импортов
  const checkDailyRewards = async (user) => {
    console.log('Checking daily rewards for user:', user?._id);
    if (!user) {
      console.log('No user found, skipping daily rewards check');
      return;
    }

    try {
      console.log('Making request to /api/daily-rewards/status');
      const response = await axios.get('/api/daily-rewards/status', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      console.log('Daily rewards response:', response.data);

      if (response.data.canClaim) {
        console.log('User can claim reward, showing modal');
        setDailyRewardStatus(response.data);
        setShowDailyReward(true);
      } else {
        console.log('User cannot claim reward yet');
      }
    } catch (error) {
      console.error('Daily rewards error:', error.response?.data || error);
    }
  };

  useEffect(() => {
    const initApp = async () => {
      console.log('Initializing app...');
      await new Promise(resolve => setTimeout(resolve, 2000));

      const authenticateUser = async () => {
        try {
          console.log('Starting authentication process');
          // Проверяем на админ панель
          if (window.location.pathname.startsWith('/admin')) {
            console.log('Admin panel detected, skipping Telegram authentication');
            const savedUser = localStorage.getItem('user');
            const savedToken = localStorage.getItem('token');
            if (savedUser && savedToken) {
              setUser(JSON.parse(savedUser));
              axios.defaults.headers.common['Authorization'] = `Bearer ${savedToken}`;
            }
            return;
          }

          telegramService.init();
          const initData = telegramService.getInitData();
          let startParam = telegramService.getStartParam();

          console.log('Telegram init data:', initData);
          console.log('Start parameter:', startParam);

          const urlParams = new URLSearchParams(window.location.search);
          const urlStartParam = urlParams.get('start');
          if (urlStartParam) {
            startParam = urlStartParam;
            console.log('Start parameter from URL:', startParam);
          }

          if (startParam) {
            console.log('Processing start parameter:', startParam);
            try {
              const referralResponse = await axios.post('/api/users/process-referral', {
                startParam: startParam,
                initData: initData
              });
              console.log('Referral processing response:', referralResponse.data);
            } catch (error) {
              console.error('Error processing referral:', error);
            }
          }

          const savedUser = localStorage.getItem('user');
          const savedToken = localStorage.getItem('token');

          if (savedUser && savedToken) {
            console.log('Using saved user data');
            const parsedUser = JSON.parse(savedUser);
            setUser(parsedUser);
            axios.defaults.headers.common['Authorization'] = `Bearer ${savedToken}`;

            // Проверяем награды для сохраненного пользователя
            console.log('Checking rewards for saved user');
            await checkDailyRewards(parsedUser);
            return;
          }

          // Если нет сохраненного пользователя, продолжаем с Telegram auth
          if (!initData) {
            console.log('No init data, redirecting to bot');
            window.location.href = 'https://t.me/BattleBrandsBot';
            return;
          }

          const userData = telegramService.getUserInfo();
          console.log('Sending auth request to server');
          const response = await axios.post('/api/users/auth', {
            start_param: startParam,
            userData: userData
          }, {
            headers: {
              'X-Telegram-Init-Data': initData,
              'X-Start-Param': startParam
            }
          });

          if (response.data && response.data.user) {
            const newUser = response.data.user;
            const token = response.data.token;
            setUser(newUser);
            localStorage.setItem('user', JSON.stringify(newUser));
            localStorage.setItem('token', token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            console.log('User authenticated, checking rewards');
            await checkDailyRewards(newUser);
          }
        } catch (err) {
          console.error('Authentication failed:', err);
          const savedUser = localStorage.getItem('user');
          if (savedUser) {
            const parsedUser = JSON.parse(savedUser);
            setUser(parsedUser);
            console.log('Using saved user, checking rewards');
            await checkDailyRewards(parsedUser);
          } else {
            setUser(null);
          }
        }
      };

      await authenticateUser();
      setLoading(false);
      setAppReady(true);
    };

    initApp();
  }, []);

  if (!appReady) {
    return <LoadingScreen />;
  }

  return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Toaster
              position="top-center"
              reverseOrder={false}
              toastOptions={{
                style: {
                  background: 'rgba(0, 0, 0, 0.7)',
                  backdropFilter: 'blur(10px)',
                  color: '#fff',
                  borderRadius: '15px',
                },
              }}
            />
            <Routes>
              <Route path="/" element={
                user ? <BrandBattle user={user} setUser={setUser} /> : <Auth onLogin={setUser} />
              } />
              <Route path="/admin/login" element={<AdminLogin />} />
              <Route path="/admin/*" element={
                <ProtectedRoute>
                  <AdminPanel />
                </ProtectedRoute>
              } />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>

            {/* Компонент ежедневных наград */}
            {showDailyReward && dailyRewardStatus && (
              <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
                <DailyRewards
                  streakDays={dailyRewardStatus.streakDays}
                  level={dailyRewardStatus.level}
                  onClaimReward={async () => {
                    try {
                      const result = await dailyRewardsService.claimDailyReward();
                      console.log('Received reward result:', result);

                      // Обновляем статус наград
                      setDailyRewardStatus({
                        ...dailyRewardStatus,
                        streakDays: result.streakDays,
                        level: result.level,
                        canClaim: false
                      });

                      // Обновляем пользователя с новым балансом
                      const updatedUser = {
                        ...user,
                        coins: result.totalCoins // Используем totalCoins из ответа сервера
                      };

                      // Обновляем состояние пользователя
                      setUser(updatedUser);

                      // Обновляем в localStorage
                      localStorage.setItem('user', JSON.stringify(updatedUser));

                      // Отправляем событие обновления баланса
                      window.dispatchEvent(new CustomEvent('balanceUpdate', {
                        detail: { coins: result.totalCoins }
                      }));

                      console.log('Updated user with new balance:', result.totalCoins);

                      // Закрываем окно через 2 секунды
                      setTimeout(() => setShowDailyReward(false), 2000);
                    } catch (error) {
                      console.error('Error claiming reward:', error);
                    }
                  }}
                  onClose={() => setShowDailyReward(false)}
                />
              </div>
            )}
          </div>
        </Router>
      </Provider>
  );
};

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return <Navigate to="/admin/login" replace />;
  }
  return children;
};

export default App;