import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { BarChart } from "../components/ui/BarChart";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { getBrandStatistics } from '../services/brandService';
import { Star, StarHalf } from 'lucide-react';

const BrandStatistics = ({ brandId }) => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedComparisonBrand, setSelectedComparisonBrand] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const data = await getBrandStatistics(brandId);
        setStats(data);
        if (data.comparisons.length > 0) {
          setSelectedComparisonBrand(data.comparisons[0].competitorBrand);
        }
        setError(null);
      } catch (error) {
        console.error('Error fetching brand statistics:', error);
        setError('Failed to fetch brand statistics. Please try again later.');
        setStats(null);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [brandId]);

  if (loading) return <div className="text-center py-4 text-white">Loading...</div>;
  if (error) return <div className="text-center py-4 text-red-500">{error}</div>;
  if (!stats) return <div className="text-center py-4 text-white">No statistics available for this brand.</div>;

  const battleData = [
    { name: 'Total Battles', value: stats.totalBattles },
    { name: 'Wins', value: stats.totalWins },
  ];

  const socialMediaData = [
    { name: 'Website Clicks', value: stats.websiteClicks },
    ...stats.socialClicks.map(sm => ({ name: `${sm.platform} Clicks`, value: sm.clicks }))
  ];

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < fullStars) {
        stars.push(<Star key={i} className="text-yellow-400 inline" />);
      } else if (i === fullStars && hasHalfStar) {
        stars.push(<StarHalf key={i} className="text-yellow-400 inline" />);
      } else {
        stars.push(<Star key={i} className="text-gray-400 inline" />);
      }
    }
    return stars;
  };

  const selectedComparison = stats.comparisons.find(c => c.competitorBrand === selectedComparisonBrand);

  return (
    <Card className="mt-4 bg-black text-white">
      <CardHeader>
        <CardTitle className="text-white">Brand Statistics</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2 text-white pb-2">Total Battles</h3>
          <BarChart data={battleData} />
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2 text-white">Comparison with Other Brands</h3>
          <Select 
            value={selectedComparisonBrand}
            onValueChange={setSelectedComparisonBrand}
          >
            <SelectTrigger className="w-full mb-2 bg-black text-white border-2 border-gray-500 focus:border-gray-400 z-[999]">
              <SelectValue placeholder="Select a brand to compare" />
            </SelectTrigger>
            <SelectContent className="bg-black border border-gray-600 z-[999]">
              <input
                type="text"
                placeholder="Brand Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full p-2 mb-2 bg-zinc-900 text-white border border-gray-600 focus:outline-none focus:border-gray-400"
              />
              <div className="max-h-48 overflow-y-auto">
                {stats.comparisons
                  .filter(comparison =>
                    comparison.competitorBrand.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .map((comparison) => (
                    <SelectItem
                      key={comparison.competitorBrand}
                      value={comparison.competitorBrand}
                      className="text-white hover:bg-gray-800"
                    >
                      {comparison.competitorBrand}
                    </SelectItem>
                  ))
                }
              </div>
            </SelectContent>
          </Select>
          {selectedComparison && (
            <BarChart data={[
              { name: 'Wins', value: selectedComparison.wins },
              { name: 'Losses', value: selectedComparison.losses },
            ]} />
          )}
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2 text-white pb-2">Social Media and Website Clicks</h3>
          <BarChart data={socialMediaData} />
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2 text-white">Rating</h3>
          <div className="text-center">
            {renderStars(stats.rating)}
            <p className="mt-2 text-2xl font-bold">{stats.rating.toFixed(2)}</p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default BrandStatistics;