import axios from 'axios';

export const getBrandStatistics = async (brandId) => {
  try {
    const response = await axios.get(`/api/brands/${brandId}/statistics`);
    return response.data;
  } catch (error) {
    console.error('Error fetching brand statistics:', error);
    throw error;
  }
};

export const getComparisonStatistics = async (brandId) => {
  try {
    const response = await axios.get(`/api/brands/${brandId}/comparisons`);
    return response.data;
  } catch (error) {
    console.error('Error fetching comparison statistics:', error);
    throw error;
  }
};