import React, { useState, useEffect } from 'react';
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { Label } from "./ui/label";
import axios from 'axios';
import { Upload, X } from 'lucide-react';
import { SiDiscord, SiFacebook, SiInstagram, SiKakaotalk, SiLine, SiLinkedin, SiMedium, SiOdnoklassniki, SiOnlyfans, SiPatreon, SiPinterest, SiTencentqq, SiReddit, SiSnapchat, SiTelegram, SiThreads, SiTiktok, SiTumblr, SiTwitch, SiViber, SiVk, SiWechat, SiSinaweibo, SiWhatsapp, SiX, SiYoutube } from 'react-icons/si';
import telegramStarsService from '../services/telegramStarsService';
import { useDropzone } from 'react-dropzone';
import { Switch } from "./ui/switch"

const socialIcons = {
  Discord: SiDiscord,
  Facebook: SiFacebook,
  Instagram: SiInstagram,
  KakaoTalk: SiKakaotalk,
  Line: SiLine,
  LinkedIn: SiLinkedin,
  Medium: SiMedium,
  Ok: SiOdnoklassniki,
  Onlyfans: SiOnlyfans,
  Patreon: SiPatreon,
  Pinterest: SiPinterest,
  QQ: SiTencentqq,
  Reddit: SiReddit,
  Snapchat: SiSnapchat,
  Telegram: SiTelegram,
  Threads: SiThreads,
  TikTok: SiTiktok,
  Tumblr: SiTumblr,
  Twitch: SiTwitch,
  Viber: SiViber,
  Vk: SiVk,
  WeChat: SiWechat,
  Weibo: SiSinaweibo,
  WhatsApp: SiWhatsapp,
  X: SiX,
  YouTube: SiYoutube,
};

const AddBrand = ({ brand, onSave, onCancel }) => {
  const [formData, setFormData] = useState(brand || {
    name: '',
    logo: '',
    description: '',
    categories: [{ category: '', subCategory: '' }],
    country: '',
    global: true,
    frequency: 0.1,
    website: '',
    socialMedia: []
  });

  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isDoubleFrequency, setIsDoubleFrequency] = useState(false);
  const [selectedSocialMedia, setSelectedSocialMedia] = useState('');
  const [logoOptions, setLogoOptions] = useState([]);
  const [logoPreview, setLogoPreview] = useState('');
  const [nameError, setNameError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [paymentUrl, setPaymentUrl] = useState('');
  const [price, setPrice] = useState({ xtr: 0, usd: 0 });
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    if (brand) {
      const newFormData = {
        ...brand,
        category: brand.category?._id || brand.category || '',
        subCategory: brand.subCategory || '',
        country: brand.country?._id || (Array.isArray(brand.countries) && brand.countries.length > 0 ? brand.countries[0]._id : '') || brand.country || '',
      };
      setFormData(newFormData);
      setLogoPreview(brand.logo || '');
      fetchLogoFromAPI(brand.name);
      console.log('Brand data:', brand);
      console.log('New form data:', newFormData);
    }
  }, [brand]);

  useEffect(() => {
    console.log('formData updated:', formData);
  }, [formData]);

  useEffect(() => {
    if (categories.length > 0 && countries.length > 0 && formData.id) {
      console.log('Categories:', categories);
      console.log('Countries:', countries);
      console.log('Current formData:', formData);

      const updatedFormData = {
        ...formData,
        category: formData.category || categories.find(c => c.name === formData.category || c._id === formData.category)?._id || '',
        country: formData.country || countries.find(c => c.name === formData.country || c._id === formData.country)?._id || ''
      };

      console.log('Updated formData:', updatedFormData);
      setFormData(updatedFormData);
    }
  }, [categories, countries, formData.id]);

  const logFormDataChanges = (changes) => {
    console.log('Form data changed:', changes);
  };

  useEffect(() => {
    fetchCategories();
    fetchCountries();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get('/api/categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get('/api/countries');
      setCountries(response.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const handleChange = async (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData(prev => {
      const newFormData = { ...prev, [name]: newValue };
      logFormDataChanges({ [name]: newValue });
      return newFormData;
    });

    if (name === 'name' && !brand) {
      fetchLogoFromAPI(value);
      try {
        const response = await axios.get(`/api/brands/check/${value}`);
        if (response.data.exists) {
          setNameError('A brand with this name already exists');
        } else {
          setNameError('');
        }
      } catch (error) {
        console.error('Error checking brand name:', error);
      }
    }
  };

  const fetchLogoFromAPI = async (brandName) => {
    try {
      const response = await axios.get(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${encodeURIComponent(brandName)}`);
      if (response.data && response.data.length > 0) {
        const logos = response.data.map(company => company.logo).filter(logo => logo);
        setLogoOptions(logos);
      } else {
        setLogoOptions([]);
      }
    } catch (error) {
      console.error('Error fetching logo from API:', error);
      setLogoOptions([]);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 100 * 1024) {
        alert('File size should not exceed 100KB');
        return;
      }
      if (!['image/jpeg', 'image/webp'].includes(file.type)) {
        alert('Only JPEG and WEBP files are allowed');
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = function () {
          if (this.width > 300 || this.height > 300) {
            alert('The image size should not exceed 300x300 pixels');
          } else {
            setFormData(prev => ({ ...prev, logo: e.target.result }));
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { 'image/jpeg': ['.jpg', '.jpeg'], 'image/webp': ['.webp'] },
    maxSize: 100 * 1024, // 100KB
    onDrop: acceptedFiles => {
      handleImageUpload(acceptedFiles[0]);
    }
  });

const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitting) return;
    setSubmitting(true);
    try {
      console.log('Submitting form data:', formData);
      const brandData = {
        name: formData.name,
        description: formData.description,
        amount: price.xtr,
        currency: 'XTR',
        // Добавьте другие необходимые поля
      };

      // Инициализация платежа через Telegram Stars
      const response = await telegramStarsService.initializePayment(brandData);
      console.log('Payment initialization response:', response);

      if (response && response.paymentUrl) {
        setPaymentStatus('pending');
        setPaymentUrl(response.paymentUrl);

        // Открываем новое окно для оплаты
        window.open(response.paymentUrl, '_blank');

        // Периодически проверяем статус платежа
        const checkPaymentStatus = setInterval(async () => {
          try {
            const statusResponse = await telegramStarsService.checkPaymentStatus(response.invoiceId);
            if (statusResponse.status === 'completed') {
              clearInterval(checkPaymentStatus);
              setPaymentStatus('completed');
              onSave({ ...formData, paidAmount: price.xtr });
            }
          } catch (error) {
            console.error('Error checking payment status:', error);
          }
        }, 5000);

        // Останавливаем проверку через 10 минут
        setTimeout(() => {
          clearInterval(checkPaymentStatus);
          if (paymentStatus !== 'completed') {
            setPaymentStatus('timeout');
          }
        }, 600000);
      } else {
        throw new Error('Invalid response from payment initialization');
      }
    } catch (error) {
      console.error('Error initializing payment:', error);
      alert(`Error: ${error.message || 'Unknown error occurred'}. Please try again.`);
      setPaymentStatus('failed');
    } finally {
      setSubmitting(false);
    }
  };


  useEffect(() => {
    const calculatePrice = () => {
      let basePrice = formData.categories.reduce((sum, catObj) => {
        const category = categories.find(cat => cat._id === catObj.category);
        return sum + (category ? category.price : 0);
      }, 0);
      const usdPrice = isDoubleFrequency ? Math.max(499, basePrice * 2) : basePrice;
      return {
        xtr: usdPrice * 50, // 1 USD = 50 XTR
        usd: usdPrice
      };
    };
    setPrice(calculatePrice());
  }, [formData.categories, categories, isDoubleFrequency]);

  const handleFrequencyChange = (checked) => {
    setIsDoubleFrequency(checked);
    setFormData(prev => ({ ...prev, frequency: checked ? 0.2 : 0.1 }));
  };

  const handleCategoryChange = (selectedCategory, index) => {
    console.log('Selected category:', selectedCategory);
    const category = categories.find(cat => cat._id === selectedCategory);
    if (category) {
      setFormData(prev => {
        const newCategories = [...prev.categories];
        newCategories[index] = { ...newCategories[index], category: selectedCategory };
        return { ...prev, categories: newCategories };
      });
      setSelectedCategories(prev => {
        const isAlreadySelected = prev.some(cat => cat._id === category._id);
        if (isAlreadySelected) {
          return prev.filter(cat => cat._id !== category._id);
        } else {
          return [...prev, category];
        }
      });
    }
  };

  const addSocialMedia = () => {
    if (selectedSocialMedia && !formData.socialMedia.find(sm => sm.platform === selectedSocialMedia)) {
      setFormData(prev => ({
        ...prev,
        socialMedia: [...prev.socialMedia, { platform: selectedSocialMedia, url: '' }]
      }));
      setSelectedSocialMedia('');
    }
  };

  const updateSocialMediaUrl = (platform, url) => {
    setFormData(prev => ({
      ...prev,
      socialMedia: prev.socialMedia.map(sm =>
        sm.platform === platform ? { ...sm, url } : sm
      )
    }));
  };

  const removeSocialMedia = (platform) => {
    setFormData(prev => ({
      ...prev,
      socialMedia: prev.socialMedia.filter(sm => sm.platform !== platform)
    }));
  };

  const handleSubCategoryChange = (selectedSubCategory) => {
    setFormData(prev => ({ ...prev, subCategory: selectedSubCategory }));
  };

  const getSubCategoryOptions = (categoryId) => {
    const selectedCategory = categories.find(cat => cat._id === categoryId);
    if (selectedCategory && selectedCategory.subCategories) {
      return selectedCategory.subCategories.map(subCategory => ({ value: subCategory, label: subCategory }));
    }
    return [];
  };

  const inputClasses = "bg-black text-[#d3d3d3] border-[#282828] border-[3px] placeholder-[#d3d3d3]";

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="flex space-x-4">
        <div className="w-2/3 space-y-2">
          <Label htmlFor="name" className="text-white">Brand Name</Label>
          <div>
            <Input
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter brand name"
              required
              className={`${inputClasses} ${nameError ? 'border-red-500' : ''}`}
            />
            {nameError && <p className="text-red-500 text-sm mt-1">{nameError}</p>}
          </div>
          {logoOptions.length > 0 && (
            <div>
              <Label className="text-white">Logo Options</Label>
              <div className="grid grid-cols-5 gap-2">
                {logoOptions.slice(0, 10).map((logo, index) => (
                  <div
                    key={index}
                    className="w-full h-20 rounded-lg border-2 border-black overflow-hidden flex items-center justify-center cursor-pointer"
                    onClick={() => {
                      setLogoPreview(logo);
                      setFormData(prev => ({ ...prev, logo }));
                    }}
                  >
                    <img src={logo} alt={`Logo option ${index + 1}`} className="max-w-full max-h-full object-contain" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="w-1/3">
          <Label className="text-white">Logo Preview</Label>
          <div className="relative">
            <div className="border-2 border-black rounded-full p-1">
              <div className="w-32 h-32 rounded-full overflow-hidden flex items-center justify-center bg-gray-200">
                {logoPreview ? (
                  <img src={logoPreview} alt="Logo preview" className="max-w-full max-h-full object-contain" />
                ) : (
                  <p className="text-gray-500 text-sm">No logo</p>
                )}
              </div>
            </div>
            {logoPreview && (
              <button
                type="button"
                className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none"
                onClick={() => {
                  setLogoPreview(null);
                  setFormData(prev => ({ ...prev, logo: null }));
                }}
              >
                <span className="sr-only">Remove logo</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <Label className="text-white">Logo (Max 300x300px, 100KB, JPEG/WEBP)</Label>
        <div {...getRootProps()} className={`p-4 border-2 border-dashed rounded-md ${isDragActive ? 'border-[#e0ff89]' : 'border-[#282828]'} flex flex-col items-center justify-center`}>
          <input {...getInputProps()} />
          <Upload className={`w-12 h-12 mb-2 ${isDragActive ? 'text-[#e0ff89]' : 'text-[#d3d3d3]'}`} />
          {
            isDragActive ?
              <p className="text-[#e0ff89]">Drop the file here ...</p> :
              <p className="text-[#d3d3d3]">Drag 'n' drop logo here, or click to select file</p>
          }
        </div>
      </div>

      <div>
        <Label htmlFor="description">Description</Label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          maxLength={200}
          className={`w-full p-2 rounded ${inputClasses}`}
          rows={4}
        />
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="categories" className="text-white">Categories</Label>
          {formData.categories.map((cat, index) => (
            <div key={index} className="flex space-x-2">
              <Select
                value={cat.category || ''}
                onValueChange={(value) => {
                  const newCategories = [...formData.categories];
                  newCategories[index].category = value;
                  newCategories[index].subCategory = '';
                  setFormData(prev => ({ ...prev, categories: newCategories }));
                }}
              >
                <SelectTrigger className={`w-full ${inputClasses}`}>
                  <SelectValue placeholder="Select Category">
                    {categories.find(c => c._id === cat.category)?.name || "Select Category"}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent className="bg-[#0f1014] text-[#d3d3d3] z-[100]">
                  {categories.map(category => (
                    <SelectItem key={category._id} value={category._id}>{category.name}</SelectItem>
                  ))}
                </SelectContent>
              </Select>

              <Select
                value={cat.subCategory || ''}
                onValueChange={(value) => {
                  const newCategories = [...formData.categories];
                  newCategories[index].subCategory = value;
                  setFormData(prev => ({ ...prev, categories: newCategories }));
                }}
              >
                <SelectTrigger className={`w-full ${inputClasses}`}>
                  <SelectValue placeholder="Select Sub-Category">
                    {cat.subCategory || "Select Sub-Category"}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent className="bg-[#0f1014] text-[#d3d3d3] z-[100]">
                  {getSubCategoryOptions(cat.category).map(option => (
                    <SelectItem key={option.value} value={option.value}>{option.label}</SelectItem>
                  ))}
                </SelectContent>
              </Select>

              {index > 0 && (
                <Button
                  type="button"
                  onClick={() => {
                    const newCategories = formData.categories.filter((_, i) => i !== index);
                    setFormData(prev => ({ ...prev, categories: newCategories }));
                  }}
                  className="bg-red-500 text-white hover:bg-red-600 rounded-full"
                >
                  <X size={16} />
                </Button>
              )}
            </div>
          ))}
          <Button
            type="button"
            onClick={() => {
              setFormData(prev => ({
                ...prev,
                categories: [...prev.categories, { category: '', subCategory: '' }]
              }));
            }}
            className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4 mt-2"
          >
            Add Category
          </Button>
        </div>
      </div>

      <div>
        <Label htmlFor="country" className="text-white">Country</Label>
        <Select
          id="country"
          name="country"
          value={formData.country || ''}
          onValueChange={(value) => {
            console.log('Selected country:', value);
            setFormData(prev => ({ ...prev, country: value }));
          }}
        >
          <SelectTrigger className={`w-full ${inputClasses}`}>
            <SelectValue placeholder="Select Country">
              {countries.find(c => c._id === formData.country)?.name || formData.country || "Select Country"}
            </SelectValue>
          </SelectTrigger>
          <SelectContent className="bg-[#0f1014] text-[#d3d3d3] z-[100] max-h-[200px] overflow-y-auto">
            {countries.map(country => (
              <SelectItem key={country._id} value={country._id}>{country.name}</SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="flex items-center space-x-2">
        <Switch
          id="doubleFrequency"
          checked={isDoubleFrequency}
          onCheckedChange={handleFrequencyChange}
        />
        <Label htmlFor="doubleFrequency">x2 (Double Number of Shows)</Label>
      </div>

      <div>
        <Label htmlFor="website">Website</Label>
        <Input
          id="website"
          name="website"
          value={formData.website}
          onChange={handleChange}
          className={inputClasses}
        />
      </div>

      <div className="space-y-2">
        <Label className="text-white">Social Media</Label>
        <div className="flex space-x-2">
          <select
            value={selectedSocialMedia}
            onChange={(e) => setSelectedSocialMedia(e.target.value)}
            className={`w-full ${inputClasses} p-2 rounded`}
          >
            <option value="">Select Social Media</option>
            {Object.keys(socialIcons).map(platform => (
              <option key={platform} value={platform}>{platform}</option>
            ))}
          </select>
          <Button
            type="button"
            onClick={addSocialMedia}
            className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
            disabled={!selectedSocialMedia}
          >
            Add
          </Button>
        </div>
        <div className="space-y-2 mt-2">
          {formData.socialMedia.map(sm => {
            const Icon = socialIcons[sm.platform];
            return (
              <div key={sm.platform} className="flex items-center space-x-2">
                <div className="w-8 h-8 rounded-full bg-[#e0ff89] flex items-center justify-center">
                  <Icon className="text-black" />
                </div>
                <Input
                  value={sm.url}
                  onChange={(e) => updateSocialMediaUrl(sm.platform, e.target.value)}
                  placeholder={`Enter ${sm.platform} URL`}
                  className={`flex-grow ${inputClasses}`}
                />
                <Button
                  type="button"
                  onClick={() => removeSocialMedia(sm.platform)}
                  className="p-2 bg-black text-white rounded-full hover:bg-red-600"
                >
                  <X size={16} />
                </Button>
              </div>
            );
          })}
        </div>
      </div>

     <div className="mt-6 space-y-4">
        <div className="text-center">
          <span className="text-2xl font-bold text-[#e0ff89] flex items-center justify-center">
            Price: {price.xtr} <img src="/images/general/Telegram-stars.png" alt="Telegram Stars" className="h-6 ml-2" />
          </span>
          <p className="text-sm text-gray-400 mt-1">
            (${price.usd.toFixed(2)} USD)
          </p>
          <p className="text-sm text-gray-400 mt-1">
            {isDoubleFrequency ? "Includes 2x brand visibility" : "Standard visibility"}
          </p>
        </div>
        <div className="flex justify-end space-x-2">
          <Button
            type="button"
            onClick={onCancel}
            variant="outline"
            className="bg-black text-white border-[#282828] hover:bg-white hover:text-black transition-colors duration-200 rounded-[30px] py-1 px-4"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
            disabled={submitting || paymentStatus === 'pending'}
          >
            {submitting ? 'Processing...' : paymentStatus === 'pending' ? 'Check Telegram for payment' : 'Save and Pay'}
          </Button>
        </div>
      </div>

      {paymentStatus === 'pending' && (
        <div className="mt-4 text-center">
          <p>Please check your Telegram chat with the bot to complete the payment using Telegram Stars.</p>
        </div>
      )}

      {paymentStatus === 'completed' && (
        <div className="mt-4 text-center text-green-500">
          Payment completed successfully! Your brand will be saved.
        </div>
      )}

      {paymentStatus === 'failed' && (
        <div className="mt-4 text-center text-red-500">
          Payment failed. Please try again.
        </div>
      )}

      {paymentStatus === 'timeout' && (
        <div className="mt-4 text-center text-yellow-500">
          Payment timeout. Please check your payment status in Telegram and try again if necessary.
        </div>
      )}
    </form>
  );
};

export default AddBrand;