export const COUNTRIES = [
    { value: 'AF', label: 'Afghanistan', icon: '🇦🇫', region: 'Asia' },
    { value: 'AL', label: 'Albania', icon: '🇦🇱', region: 'Europe' },
    { value: 'DZ', label: 'Algeria', icon: '🇩🇿', region: 'Africa' },
    { value: 'AS', label: 'American Samoa', icon: '🇦🇸', region: 'Oceania' },
    { value: 'AD', label: 'Andorra', icon: '🇦🇩', region: 'Europe' },
    { value: 'AO', label: 'Angola', icon: '🇦🇴', region: 'Africa' },
    { value: 'AI', label: 'Anguilla', icon: '🇦🇮', region: 'North America' },
    { value: 'AQ', label: 'Antarctica', icon: '🇦🇶', region: 'Antarctica' },
    { value: 'AG', label: 'Antigua and Barbuda', icon: '🇦🇬', region: 'North America' },
    { value: 'AR', label: 'Argentina', icon: '🇦🇷', region: 'South America' },
    { value: 'AM', label: 'Armenia', icon: '🇦🇲', region: 'Asia' },
    { value: 'AW', label: 'Aruba', icon: '🇦🇼', region: 'North America' },
    { value: 'AU', label: 'Australia', icon: '🇦🇺', region: 'Oceania' },
    { value: 'AT', label: 'Austria', icon: '🇦🇹', region: 'Europe' },
    { value: 'AZ', label: 'Azerbaijan', icon: '🇦🇿', region: 'Asia' },
    { value: 'BS', label: 'Bahamas', icon: '🇧🇸', region: 'North America' },
    { value: 'BH', label: 'Bahrain', icon: '🇧🇭', region: 'Asia' },
    { value: 'BD', label: 'Bangladesh', icon: '🇧🇩', region: 'Asia' },
    { value: 'BB', label: 'Barbados', icon: '🇧🇧', region: 'North America' },
    { value: 'BY', label: 'Belarus', icon: '🇧🇾', region: 'Europe' },
    { value: 'BE', label: 'Belgium', icon: '🇧🇪', region: 'Europe' },
    { value: 'BZ', label: 'Belize', icon: '🇧🇿', region: 'North America' },
    { value: 'BJ', label: 'Benin', icon: '🇧🇯', region: 'Africa' },
    { value: 'BM', label: 'Bermuda', icon: '🇧🇲', region: 'North America' },
    { value: 'BT', label: 'Bhutan', icon: '🇧🇹', region: 'Asia' },
    { value: 'BO', label: 'Bolivia', icon: '🇧🇴', region: 'South America' },
    { value: 'BA', label: 'Bosnia and Herzegovina', icon: '🇧🇦', region: 'Europe' },
    { value: 'BW', label: 'Botswana', icon: '🇧🇼', region: 'Africa' },
    { value: 'BV', label: 'Bouvet Island', icon: '🇧🇻', region: 'Antarctica' },
    { value: 'BR', label: 'Brazil', icon: '🇧🇷', region: 'South America' },
    { value: 'IO', label: 'British Indian Ocean Territory', icon: '🇮🇴', region: 'Asia' },
    { value: 'BN', label: 'Brunei Darussalam', icon: '🇧🇳', region: 'Asia' },
    { value: 'BG', label: 'Bulgaria', icon: '🇧🇬', region: 'Europe' },
    { value: 'BF', label: 'Burkina Faso', icon: '🇧🇫', region: 'Africa' },
    { value: 'BI', label: 'Burundi', icon: '🇧🇮', region: 'Africa' },
    { value: 'KH', label: 'Cambodia', icon: '🇰🇭', region: 'Asia' },
    { value: 'CM', label: 'Cameroon', icon: '🇨🇲', region: 'Africa' },
    { value: 'CA', label: 'Canada', icon: '🇨🇦', region: 'North America' },
    { value: 'CV', label: 'Cape Verde', icon: '🇨🇻', region: 'Africa' },
    { value: 'KY', label: 'Cayman Islands', icon: '🇰🇾', region: 'North America' },
    { value: 'CF', label: 'Central African Republic', icon: '🇨🇫', region: 'Africa' },
    { value: 'TD', label: 'Chad', icon: '🇹🇩', region: 'Africa' },
    { value: 'CL', label: 'Chile', icon: '🇨🇱', region: 'South America' },
    { value: 'CN', label: 'China', icon: '🇨🇳', region: 'Asia' },
    { value: 'CX', label: 'Christmas Island', icon: '🇨🇽', region: 'Asia' },
    { value: 'CC', label: 'Cocos (Keeling) Islands', icon: '🇨🇨', region: 'Asia' },
    { value: 'CO', label: 'Colombia', icon: '🇨🇴', region: 'South America' },
    { value: 'KM', label: 'Comoros', icon: '🇰🇲', region: 'Africa' },
    { value: 'CG', label: 'Congo', icon: '🇨🇬', region: 'Africa' },
    { value: 'CD', label: 'Congo, Democratic Republic', icon: '🇨🇩', region: 'Africa' },
    { value: 'CK', label: 'Cook Islands', icon: '🇨🇰', region: 'Oceania' },
    { value: 'CR', label: 'Costa Rica', icon: '🇨🇷', region: 'North America' },
    { value: 'CI', label: "Côte d'Ivoire", icon: '🇨🇮', region: 'Africa' },
    { value: 'HR', label: 'Croatia', icon: '🇭🇷', region: 'Europe' },
    { value: 'CU', label: 'Cuba', icon: '🇨🇺', region: 'North America' },
    { value: 'CY', label: 'Cyprus', icon: '🇨🇾', region: 'Europe' },
    { value: 'CZ', label: 'Czech Republic', icon: '🇨🇿', region: 'Europe' },
    { value: 'DK', label: 'Denmark', icon: '🇩🇰', region: 'Europe' },
    { value: 'DJ', label: 'Djibouti', icon: '🇩🇯', region: 'Africa' },
    { value: 'DM', label: 'Dominica', icon: '🇩🇲', region: 'North America' },
    { value: 'DO', label: 'Dominican Republic', icon: '🇩🇴', region: 'North America' },
    { value: 'EC', label: 'Ecuador', icon: '🇪🇨', region: 'South America' },
    { value: 'EG', label: 'Egypt', icon: '🇪🇬', region: 'Africa' },
    { value: 'SV', label: 'El Salvador', icon: '🇸🇻', region: 'North America' },
    { value: 'GQ', label: 'Equatorial Guinea', icon: '🇬🇶', region: 'Africa' },
    { value: 'ER', label: 'Eritrea', icon: '🇪🇷', region: 'Africa' },
    { value: 'EE', label: 'Estonia', icon: '🇪🇪', region: 'Europe' },
    { value: 'ET', label: 'Ethiopia', icon: '🇪🇹', region: 'Africa' },
    { value: 'FK', label: 'Falkland Islands', icon: '🇫🇰', region: 'South America' },
    { value: 'FO', label: 'Faroe Islands', icon: '🇫🇴', region: 'Europe' },
    { value: 'FJ', label: 'Fiji', icon: '🇫🇯', region: 'Oceania' },
    { value: 'FI', label: 'Finland', icon: '🇫🇮', region: 'Europe' },
    { value: 'FR', label: 'France', icon: '🇫🇷', region: 'Europe' },
    { value: 'GF', label: 'French Guiana', icon: '🇬🇫', region: 'South America' },
    { value: 'PF', label: 'French Polynesia', icon: '🇵🇫', region: 'Oceania' },
    { value: 'TF', label: 'French Southern Territories', icon: '🇹🇫', region: 'Antarctica' },
    { value: 'GA', label: 'Gabon', icon: '🇬🇦', region: 'Africa' },
    { value: 'GM', label: 'Gambia', icon: '🇬🇲', region: 'Africa' },
    { value: 'GE', label: 'Georgia', icon: '🇬🇪', region: 'Asia' },
    { value: 'DE', label: 'Germany', icon: '🇩🇪', region: 'Europe' },
    { value: 'GH', label: 'Ghana', icon: '🇬🇭', region: 'Africa' },
    { value: 'GI', label: 'Gibraltar', icon: '🇬🇮', region: 'Europe' },
    { value: 'GR', label: 'Greece', icon: '🇬🇷', region: 'Europe' },
    { value: 'GL', label: 'Greenland', icon: '🇬🇱', region: 'North America' },
    { value: 'GD', label: 'Grenada', icon: '🇬🇩', region: 'North America' },
    { value: 'GP', label: 'Guadeloupe', icon: '🇬🇵', region: 'North America' },
    { value: 'GU', label: 'Guam', icon: '🇬🇺', region: 'Oceania' },
    { value: 'GT', label: 'Guatemala', icon: '🇬🇹', region: 'North America' },
    { value: 'GG', label: 'Guernsey', icon: '🇬🇬', region: 'Europe' },
    { value: 'GN', label: 'Guinea', icon: '🇬🇳', region: 'Africa' },
    { value: 'GW', label: 'Guinea-Bissau', icon: '🇬🇼', region: 'Africa' },
    { value: 'GY', label: 'Guyana', icon: '🇬🇾', region: 'South America' },
    { value: 'HT', label: 'Haiti', icon: '🇭🇹', region: 'North America' },
    { value: 'HM', label: 'Heard Island and McDonald Islands', icon: '🇭🇲', region: 'Antarctica' },
    { value: 'VA', label: 'Holy See (Vatican City State)', icon: '🇻🇦', region: 'Europe' },
    { value: 'HN', label: 'Honduras', icon: '🇭🇳', region: 'North America' },
    { value: 'HK', label: 'Hong Kong', icon: '🇭🇰', region: 'Asia' },
    { value: 'HU', label: 'Hungary', icon: '🇭🇺', region: 'Europe' },
    { value: 'IS', label: 'Iceland', icon: '🇮🇸', region: 'Europe' },
    { value: 'IN', label: 'India', icon: '🇮🇳', region: 'Asia' },
    { value: 'ID', label: 'Indonesia', icon: '🇮🇩', region: 'Asia' },
    { value: 'IR', label: 'Iran', icon: '🇮🇷', region: 'Asia' },
    { value: 'IQ', label: 'Iraq', icon: '🇮🇶', region: 'Asia' },
    { value: 'IE', label: 'Ireland', icon: '🇮🇪', region: 'Europe' },
    { value: 'IM', label: 'Isle of Man', icon: '🇮🇲', region: 'Europe' },
    { value: 'IL', label: 'Israel', icon: '🇮🇱', region: 'Asia' },
    { value: 'IT', label: 'Italy', icon: '🇮🇹', region: 'Europe' },
    { value: 'JM', label: 'Jamaica', icon: '🇯🇲', region: 'North America' },
    { value: 'JP', label: 'Japan', icon: '🇯🇵', region: 'Asia' },
    { value: 'JE', label: 'Jersey', icon: '🇯🇪', region: 'Europe' },
    { value: 'JO', label: 'Jordan', icon: '🇯🇴', region: 'Asia' },
    { value: 'KZ', label: 'Kazakhstan', icon: '🇰🇿', region: 'Asia' },
    { value: 'KE', label: 'Kenya', icon: '🇰🇪', region: 'Africa' },
    { value: 'KI', label: 'Kiribati', icon: '🇰🇮', region: 'Oceania' },
    { value: 'KP', label: 'Korea, North', icon: '🇰🇵', region: 'Asia' },
    { value: 'KR', label: 'Korea, South', icon: '🇰🇷', region: 'Asia' },
    { value: 'KW', label: 'Kuwait', icon: '🇰🇼', region: 'Asia' },
    { value: 'KG', label: 'Kyrgyzstan', icon: '🇰🇬', region: 'Asia' },
    { value: 'LA', label: 'Laos', icon: '🇱🇦', region: 'Asia' },
    { value: 'LV', label: 'Latvia', icon: '🇱🇻', region: 'Europe' },
    { value: 'LB', label: 'Lebanon', icon: '🇱🇧', region: 'Asia' },
    { value: 'LS', label: 'Lesotho', icon: '🇱🇸', region: 'Africa' },
    { value: 'LR', label: 'Liberia', icon: '🇱🇷', region: 'Africa' },
    { value: 'LY', label: 'Libya', icon: '🇱🇾', region: 'Africa' },
    { value: 'LI', label: 'Liechtenstein', icon: '🇱🇮', region: 'Europe' },
    { value: 'LT', label: 'Lithuania', icon: '🇱🇹', region: 'Europe' },
    { value: 'LU', label: 'Luxembourg', icon: '🇱🇺', region: 'Europe' },
    { value: 'MO', label: 'Macao', icon: '🇲🇴', region: 'Asia' },
    { value: 'MK', label: 'Macedonia', icon: '🇲🇰', region: 'Europe' },
    { value: 'MG', label: 'Madagascar', icon: '🇲🇬', region: 'Africa' },
    { value: 'MW', label: 'Malawi', icon: '🇲🇼', region: 'Africa' },
    { value: 'MY', label: 'Malaysia', icon: '🇲🇾', region: 'Asia' },
    { value: 'MV', label: 'Maldives', icon: '🇲🇻', region: 'Asia' },
    { value: 'ML', label: 'Mali', icon: '🇲🇱', region: 'Africa' },
    { value: 'MT', label: 'Malta', icon: '🇲🇹', region: 'Europe' },
    { value: 'MH', label: 'Marshall Islands', icon: '🇲🇭', region: 'Oceania' },
    { value: 'MQ', label: 'Martinique', icon: '🇲🇶', region: 'North America' },
    { value: 'MR', label: 'Mauritania', icon: '🇲🇷', region: 'Africa' },
    { value: 'MU', label: 'Mauritius', icon: '🇲🇺', region: 'Africa' },
    { value: 'YT', label: 'Mayotte', icon: '🇾🇹', region: 'Africa' },
    { value: 'MX', label: 'Mexico', icon: '🇲🇽', region: 'North America' },
    { value: 'FM', label: 'Micronesia', icon: '🇫🇲', region: 'Oceania' },
    { value: 'MD', label: 'Moldova', icon: '🇲🇩', region: 'Europe' },
    { value: 'MC', label: 'Monaco', icon: '🇲🇨', region: 'Europe' },
    { value: 'MN', label: 'Mongolia', icon: '🇲🇳', region: 'Asia' },
    { value: 'ME', label: 'Montenegro', icon: '🇲🇪', region: 'Europe' },
    { value: 'MS', label: 'Montserrat', icon: '🇲🇸', region: 'North America' },
    { value: 'MA', label: 'Morocco', icon: '🇲🇦', region: 'Africa' },
    { value: 'MZ', label: 'Mozambique', icon: '🇲🇿', region: 'Africa' },
    { value: 'MM', label: 'Myanmar', icon: '🇲🇲', region: 'Asia' },
    { value: 'NA', label: 'Namibia', icon: '🇳🇦', region: 'Africa' },
    { value: 'NR', label: 'Nauru', icon: '🇳🇷', region: 'Oceania' },
    { value: 'NP', label: 'Nepal', icon: '🇳🇵', region: 'Asia' },
    { value: 'NL', label: 'Netherlands', icon: '🇳🇱', region: 'Europe' },
    { value: 'NC', label: 'New Caledonia', icon: '🇳🇨', region: 'Oceania' },
    { value: 'NZ', label: 'New Zealand', icon: '🇳🇿', region: 'Oceania' },
    { value: 'NI', label: 'Nicaragua', icon: '🇳🇮', region: 'North America' },
    { value: 'NE', label: 'Niger', icon: '🇳🇪', region: 'Africa' },
    { value: 'NG', label: 'Nigeria', icon: '🇳🇬', region: 'Africa' },
    { value: 'NU', label: 'Niue', icon: '🇳🇺', region: 'Oceania' },
    { value: 'NF', label: 'Norfolk Island', icon: '🇳🇫', region: 'Oceania' },
    { value: 'MP', label: 'Northern Mariana Islands', icon: '🇲🇵', region: 'Oceania' },
    { value: 'NO', label: 'Norway', icon: '🇳🇴', region: 'Europe' },
    { value: 'OM', label: 'Oman', icon: '🇴🇲', region: 'Asia' },
    { value: 'PK', label: 'Pakistan', icon: '🇵🇰', region: 'Asia' },
    { value: 'PW', label: 'Palau', icon: '🇵🇼', region: 'Oceania' },
    { value: 'PS', label: 'Palestine', icon: '🇵🇸', region: 'Asia' },
    { value: 'PA', label: 'Panama', icon: '🇵🇦', region: 'North America' },
    { value: 'PG', label: 'Papua New Guinea', icon: '🇵🇬', region: 'Oceania' },
    { value: 'PY', label: 'Paraguay', icon: '🇵🇾', region: 'South America' },
    { value: 'PE', label: 'Peru', icon: '🇵🇪', region: 'South America' },
    { value: 'PH', label: 'Philippines', icon: '🇵🇭', region: 'Asia' },
    { value: 'PN', label: 'Pitcairn', icon: '🇵🇳', region: 'Oceania' },
    { value: 'PL', label: 'Poland', icon: '🇵🇱', region: 'Europe' },
    { value: 'PT', label: 'Portugal', icon: '🇵🇹', region: 'Europe' },
    { value: 'PR', label: 'Puerto Rico', icon: '🇵🇷', region: 'North America' },
    { value: 'QA', label: 'Qatar', icon: '🇶🇦', region: 'Asia' },
    { value: 'RE', label: 'Reunion', icon: '🇷🇪', region: 'Africa' },
    { value: 'RO', label: 'Romania', icon: '🇷🇴', region: 'Europe' },
    { value: 'RU', label: 'Russian Federation', icon: '🇷🇺', region: 'Europe' },
    { value: 'RW', label: 'Rwanda', icon: '🇷🇼', region: 'Africa' },
    { value: 'BL', label: 'Saint Barthélemy', icon: '🇧🇱', region: 'North America' },
    { value: 'SH', label: 'Saint Helena', icon: '🇸🇭', region: 'Africa' },
    { value: 'KN', label: 'Saint Kitts and Nevis', icon: '🇰🇳', region: 'North America' },
    { value: 'LC', label: 'Saint Lucia', icon: '🇱🇨', region: 'North America' },
    { value: 'MF', label: 'Saint Martin (French part)', icon: '🇲🇫', region: 'North America' },
    { value: 'PM', label: 'Saint Pierre and Miquelon', icon: '🇵🇲', region: 'North America' },
    { value: 'VC', label: 'Saint Vincent and the Grenadines', icon: '🇻🇨', region: 'North America' },
    { value: 'WS', label: 'Samoa', icon: '🇼🇸', region: 'Oceania' },
    { value: 'SM', label: 'San Marino', icon: '🇸🇲', region: 'Europe' },
    { value: 'ST', label: 'Sao Tome and Principe', icon: '🇸🇹', region: 'Africa' },
    { value: 'SA', label: 'Saudi Arabia', icon: '🇸🇦', region: 'Asia' },
    { value: 'SN', label: 'Senegal', icon: '🇸🇳', region: 'Africa' },
    { value: 'RS', label: 'Serbia', icon: '🇷🇸', region: 'Europe' },
    { value: 'SC', label: 'Seychelles', icon: '🇸🇨', region: 'Africa' },
    { value: 'SL', label: 'Sierra Leone', icon: '🇸🇱', region: 'Africa' },
    { value: 'SG', label: 'Singapore', icon: '🇸🇬', region: 'Asia' },
    { value: 'SX', label: 'Sint Maarten', icon: '🇸🇽', region: 'North America' },
    { value: 'SK', label: 'Slovakia', icon: '🇸🇰', region: 'Europe' },
    { value: 'SI', label: 'Slovenia', icon: '🇸🇮', region: 'Europe' },
    { value: 'SB', label: 'Solomon Islands', icon: '🇸🇧', region: 'Oceania' },
    { value: 'SO', label: 'Somalia', icon: '🇸🇴', region: 'Africa' },
    { value: 'ZA', label: 'South Africa', icon: '🇿🇦', region: 'Africa' },
    { value: 'GS', label: 'South Georgia', icon: '🇬🇸', region: 'Antarctica' },
    { value: 'SS', label: 'South Sudan', icon: '🇸🇸', region: 'Africa' },
    { value: 'ES', label: 'Spain', icon: '🇪🇸', region: 'Europe' },
    { value: 'LK', label: 'Sri Lanka', icon: '🇱🇰', region: 'Asia' },
    { value: 'SD', label: 'Sudan', icon: '🇸🇩', region: 'Africa' },
    { value: 'SR', label: 'Suriname', icon: '🇸🇷', region: 'South America' },
    { value: 'SJ', label: 'Svalbard and Jan Mayen', icon: '🇸🇯', region: 'Europe' },
    { value: 'SZ', label: 'Swaziland', icon: '🇸🇿', region: 'Africa' },
    { value: 'SE', label: 'Sweden', icon: '🇸🇪', region: 'Europe' },
    { value: 'CH', label: 'Switzerland', icon: '🇨🇭', region: 'Europe' },
    { value: 'SY', label: 'Syria', icon: '🇸🇾', region: 'Asia' },
    { value: 'TW', label: 'Taiwan', icon: '🇹🇼', region: 'Asia' },
    { value: 'TJ', label: 'Tajikistan', icon: '🇹🇯', region: 'Asia' },
    { value: 'TZ', label: 'Tanzania', icon: '🇹🇿', region: 'Africa' },
    { value: 'TH', label: 'Thailand', icon: '🇹🇭', region: 'Asia' },
    { value: 'TL', label: 'Timor-Leste', icon: '🇹🇱', region: 'Asia' },
    { value: 'TG', label: 'Togo', icon: '🇹🇬', region: 'Africa' },
    { value: 'TK', label: 'Tokelau', icon: '🇹🇰', region: 'Oceania' },
    { value: 'TO', label: 'Tonga', icon: '🇹🇴', region: 'Oceania' },
    { value: 'TT', label: 'Trinidad and Tobago', icon: '🇹🇹', region: 'North America' },
    { value: 'TN', label: 'Tunisia', icon: '🇹🇳', region: 'Africa' },
    { value: 'TR', label: 'Turkey', icon: '🇹🇷', region: 'Asia' },
    { value: 'TM', label: 'Turkmenistan', icon: '🇹🇲', region: 'Asia' },
    { value: 'TC', label: 'Turks and Caicos Islands', icon: '🇹🇨', region: 'North America' },
    { value: 'TV', label: 'Tuvalu', icon: '🇹🇻', region: 'Oceania' },
    { value: 'UG', label: 'Uganda', icon: '🇺🇬', region: 'Africa' },
    { value: 'UA', label: 'Ukraine', icon: '🇺🇦', region: 'Europe' },
    { value: 'AE', label: 'United Arab Emirates', icon: '🇦🇪', region: 'Asia' },
    { value: 'GB', label: 'United Kingdom', icon: '🇬🇧', region: 'Europe' },
    { value: 'US', label: 'United States', icon: '🇺🇸', region: 'North America' },
    { value: 'UM', label: 'United States Minor Outlying Islands', icon: '🇺🇲', region: 'North America' },
    { value: 'UY', label: 'Uruguay', icon: '🇺🇾', region: 'South America' },
    { value: 'UZ', label: 'Uzbekistan', icon: '🇺🇿', region: 'Asia' },
    { value: 'VU', label: 'Vanuatu', icon: '🇻🇺', region: 'Oceania' },
    { value: 'VE', label: 'Venezuela', icon: '🇻🇪', region: 'South America' },
    { value: 'VN', label: 'Vietnam', icon: '🇻🇳', region: 'Asia' },
    { value: 'VG', label: 'Virgin Islands, British', icon: '🇻🇬', region: 'North America' },
    { value: 'VI', label: 'Virgin Islands, U.S.', icon: '🇻🇮', region: 'North America' },
    { value: 'WF', label: 'Wallis and Futuna', icon: '🇼🇫', region: 'Oceania' },
    { value: 'EH', label: 'Western Sahara', icon: '🇪🇭', region: 'Africa' },
    { value: 'YE', label: 'Yemen', icon: '🇾🇪', region: 'Asia' },
    { value: 'ZM', label: 'Zambia', icon: '🇿🇲', region: 'Africa' },
    { value: 'ZW', label: 'Zimbabwe', icon: '🇿🇼', region: 'Africa' }
];

// Получение страны по коду
export const getCountryByCode = (code) => {
  return COUNTRIES.find(country => country.value === code);
};

// Получение названия страны по коду
export const getCountryLabel = (code) => {
  const country = getCountryByCode(code);
  return country ? country.label : code;
};

// Проверка поддержки страны
export const isCountrySupported = (code) => {
  return COUNTRIES.some(country => country.value === code);
};

// Получение списка стран по региону
export const getCountriesByRegion = (region) => {
  return COUNTRIES.filter(country => country.region === region);
};

// Получение всех доступных регионов
export const getAvailableRegions = () => {
  const regions = new Set(COUNTRIES.map(country => country.region));
  return Array.from(regions).sort();
};

// Поиск стран по запросу
export const searchCountries = (query) => {
  const searchTerm = query.toLowerCase();
  return COUNTRIES.filter(country => 
    country.label.toLowerCase().includes(searchTerm) || 
    country.value.toLowerCase().includes(searchTerm)
  );
};

// Группировка стран по региону
export const groupCountriesByRegion = () => {
  const grouped = {};
  COUNTRIES.forEach(country => {
    if (!grouped[country.region]) {
      grouped[country.region] = [];
    }
    grouped[country.region].push(country);
  });
  return grouped;
};

// Получение списка популярных стран
export const getPopularCountries = () => {
  const popularCountryCodes = ['US', 'GB', 'DE', 'FR', 'IT', 'ES', 'CN', 'JP', 'KR', 'RU'];
  return COUNTRIES.filter(country => popularCountryCodes.includes(country.value));
};