import React, { useEffect, useState } from 'react';
import { TonConnectUIProvider, TonConnectButton, useTonAddress, useTonWallet } from '@tonconnect/ui-react';
import axios from 'axios';

const WalletContent = ({ userId }) => {
  const userAddress = useTonAddress();
  const wallet = useTonWallet();
  const [balance, setBalance] = useState(null);

  // Обновляем информацию о кошельке
  const updateWallet = async () => {
    if (userAddress && userId) {
      try {
        const response = await axios.post('/api/users/update-wallet', {
          userId,
          walletAddress: userAddress
        });
        if (response.data?.tonWallet?.balance) {
          setBalance(response.data.tonWallet.balance);
        }
      } catch (error) {
        console.error('Error updating wallet:', error);
      }
    }
  };

  // Обновляем при подключении и каждые 30 секунд
  useEffect(() => {
    if (wallet && userAddress) {
      updateWallet();
      const interval = setInterval(updateWallet, 30000);
      return () => clearInterval(interval);
    }
  }, [wallet, userAddress, userId]);

  return (
    <div className="bg-[#1a1a1a] rounded-[20px] p-4">
      <h3 className="text-sm font-semibold text-[#e0ff89] mb-2">
        Connect TON Wallet
      </h3>
      
      <p className="text-gray-400 text-sm mb-4">
        Connect your TON wallet to receive rewards and participate in battles
      </p>

      {wallet && balance !== null && (
        <div className="mb-4">
          <p className="text-sm text-gray-400">Balance:</p>
          <p className="text-lg text-[#e0ff89] font-medium">
            {balance.toFixed(2)} TON
          </p>
        </div>
      )}
      
      <div className="w-full inline-flex">
        <TonConnectButton />
      </div>
    </div>
  );
};

const TonWallet = ({ userId }) => {
  return (
    <TonConnectUIProvider manifestUrl="https://battlebrands.app/tonconnect-manifest.json">
      <WalletContent userId={userId} />
    </TonConnectUIProvider>
  );
};

export default TonWallet;