import axios from 'axios';

const API_BASE_URL = '/api/payments/telegram-stars';

const handleAxiosError = (error, operation) => {
  console.error(`Error in ${operation}:`, error);
  if (error.response) {
    console.error('Response data:', error.response.data);
    console.error('Response status:', error.response.status);
    console.error('Response headers:', error.response.headers);
    throw new Error(`${operation} failed: ${error.response.data.message || error.response.statusText}`);
  } else if (error.request) {
    console.error('No response received:', error.request);
    throw new Error(`${operation} failed: No response from server`);
  } else {
    console.error('Error details:', error.message);
    throw new Error(`${operation} failed: ${error.message}`);
  }
};

const validatePaymentData = (paymentData) => {
  const requiredFields = ['name', 'description', 'amount', 'currency'];
  for (const field of requiredFields) {
    if (!paymentData[field]) {
      throw new Error(`Missing required field: ${field}`);
    }
  }
  if (typeof paymentData.amount !== 'number' || paymentData.amount <= 0) {
    throw new Error('Invalid amount');
  }
};

const telegramStarsService = {
  initializePayment: async (paymentData) => {
    try {
      console.log('Initializing payment with data:', paymentData);
      validatePaymentData(paymentData);

      const response = await axios.post(`${API_BASE_URL}/initialize`, paymentData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log('Payment initialization response:', response.data);
      if (response.data && response.data.paymentUrl) {
        return response.data;
      } else {
        throw new Error('Invalid response from payment initialization');
      }
    } catch (error) {
      handleAxiosError(error, 'Payment initialization');
    }
  },
  
  checkPaymentStatus: async (invoiceId) => {
    try {
      if (!invoiceId) {
        throw new Error('Invoice ID is required');
      }

      console.log('Checking payment status for invoice:', invoiceId);
      const response = await axios.get(`${API_BASE_URL}/status/${invoiceId}`);

      console.log('Received payment status:', response.data);
      if (!response.data || !response.data.status) {
        throw new Error('Invalid response from payment status check');
      }
      return response.data;
    } catch (error) {
      handleAxiosError(error, 'Payment status check');
    }
  }
};

export default telegramStarsService;