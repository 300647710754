import React, { useState, useEffect } from 'react';
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { MultiSelect } from "../ui/MultiSelect";
import { Label } from "../ui/label";
import ImageUpload from '../ui/ImageUpload';
import axios from 'axios';
import { X } from 'lucide-react';
import { SiDiscord, SiFacebook, SiInstagram, SiKakaotalk, SiLine, SiLinkedin, SiMedium, SiOdnoklassniki, SiOnlyfans, SiPatreon, SiPinterest, SiTencentqq, SiReddit, SiSnapchat, SiTelegram, SiThreads, SiTiktok, SiTumblr, SiTwitch, SiViber, SiVk, SiWechat, SiSinaweibo, SiWhatsapp, SiX, SiYoutube } from 'react-icons/si';

const socialIcons = {
  Discord: SiDiscord,
  Facebook: SiFacebook,
  Instagram: SiInstagram,
  KakaoTalk: SiKakaotalk,
  Line: SiLine,
  LinkedIn: SiLinkedin,
  Medium: SiMedium,
  Ok: SiOdnoklassniki,
  Onlyfans: SiOnlyfans,
  Patreon: SiPatreon,
  Pinterest: SiPinterest,
  QQ: SiTencentqq,
  Reddit: SiReddit,
  Snapchat: SiSnapchat,
  Telegram: SiTelegram,
  Threads: SiThreads,
  TikTok: SiTiktok,
  Tumblr: SiTumblr,
  Twitch: SiTwitch,
  Viber: SiViber,
  Vk: SiVk,
  WeChat: SiWechat,
  Weibo: SiSinaweibo,
  WhatsApp: SiWhatsapp,
  X: SiX,
  YouTube: SiYoutube,
};

const BrandForm = ({ brand, onSave, onCancel, categories, countries }) => {
  const [formData, setFormData] = useState({
    name: '',
    logo: '',
    description: '',
    categories: [{ category: '', subCategory: '' }],
    countries: [],
    website: '',
    socialMedia: [],
    global: false,
    frequency: 0.1,
    activeDays: -1,
    status: 'active',
    owner: ''
  });
  const [users, setUsers] = useState([]);
  const [selectedSocialMedia, setSelectedSocialMedia] = useState('');
  const [logoPreview, setLogoPreview] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (brand) {
      console.log('Received brand data:', brand);
      console.log('Brand categories:', brand.categories);
      console.log('Brand owner:', brand.owner);

      const formattedCategories = Array.isArray(brand.categories)
        ? brand.categories.map(cat => ({
          category: cat.category?._id || cat.category || '',
          subCategory: cat.subCategory || ''
        }))
        : [{ category: '', subCategory: '' }];

      const formattedCountries = Array.isArray(brand.countries)
        ? brand.countries.map(country => typeof country === 'object' ? country._id : country)
        : [];

      let formattedOwner;
      if (brand.owner === null) {
        formattedOwner = 'admin';
      } else if (typeof brand.owner === 'object' && brand.owner !== null) {
        formattedOwner = brand.owner._id;
      } else {
        formattedOwner = brand.owner;
      }

      const newFormData = {
        ...brand,
        categories: formattedCategories,
        countries: formattedCountries,
        owner: formattedOwner
      };

      console.log('Formatted owner:', formattedOwner);
      console.log('Setting form data:', newFormData);
      setFormData(newFormData);
      setLogoPreview(brand.logo || '');

      console.log('Formatted categories:', formattedCategories);
      console.log('Formatted owner:', formattedOwner);

      const selectedCats = formattedCategories.map(cat =>
        categories.find(c => c._id === cat.category)
      ).filter(Boolean);
      setSelectedCategories(selectedCats);

      console.log('Selected categories:', selectedCats);
    } else {
      // Сброс формы, если brand не предоставлен
      setFormData({
        name: '',
        logo: '',
        description: '',
        categories: [{ category: '', subCategory: '' }],
        countries: [],
        website: '',
        socialMedia: [],
        global: false,
        frequency: 0.1,
        activeDays: -1,
        status: 'active',
        owner: 'admin'
      });
      setSelectedCategories([]);
      setLogoPreview('');
    }
    setIsLoading(false);
  }, [brand, categories]);

  // Добавьте этот useEffect для отладки
  useEffect(() => {
    console.log('formData updated:', formData);
    console.log('Current owner:', formData.owner);
  }, [formData]);

  useEffect(() => {
    if (formData) {
      console.log('Current formData:', formData);
      console.log('Selected categories:', selectedCategories);
    }
  }, [formData, selectedCategories]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error when retrieving users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));

    if (name === 'name') {
      fetchLogoFromAPI(value);
    }
  };

  const [logoOptions, setLogoOptions] = useState([]);

  const fetchLogoFromAPI = async (brandName) => {
    try {
      const response = await axios.get(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${encodeURIComponent(brandName)}`);
      if (response.data && response.data.length > 0) {
        const logos = response.data.map(company => company.logo).filter(logo => logo);
        setLogoOptions(logos);
      } else {
        setLogoOptions([]);
      }
    } catch (error) {
      console.error('Error fetching logo from API:', error);
      setLogoOptions([]);
    }
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    try {
      const response = await axios.post('/api/upload-image', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      const imageUrl = response.data.imageUrl;
      setLogoPreview(imageUrl);
      setFormData(prev => ({ ...prev, logo: imageUrl }));
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleCountriesChange = (selectedCountries) => {
    setFormData(prev => ({ ...prev, countries: selectedCountries }));
  };

  const handleCategoryChange = (index, selectedCategory) => {
    console.log('Category change attempt:', index, selectedCategory);
    setFormData(prev => {
      const newCategories = [...prev.categories];
      newCategories[index] = { ...newCategories[index], category: selectedCategory };
      console.log('New categories after change:', newCategories);
      return { ...prev, categories: newCategories };
    });
  };

  const handleSubCategoryChange = (index, selectedSubCategory) => {
    console.log('Subcategory change attempt:', index, selectedSubCategory);
    setFormData(prev => {
      const newCategories = [...prev.categories];
      newCategories[index] = { ...newCategories[index], subCategory: selectedSubCategory };
      console.log('New categories after subcategory change:', newCategories);
      return { ...prev, categories: newCategories };
    });
  };

  const addCategory = () => {
    setFormData(prev => ({
      ...prev,
      categories: [...prev.categories, { category: '', subCategory: '' }]
    }));
  };

  const removeCategory = (index) => {
    setFormData(prev => ({
      ...prev,
      categories: prev.categories.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSave = {
      ...formData,
      categories: formData.categories.filter(cat => cat.category && cat.subCategory)
    };
    await onSave(dataToSave);
  };

  const getCategoryOptions = () => {
    return categories.map(category => ({ value: category._id, label: category.name }));
  };

  const getSubCategoryOptions = (categoryId) => {
    const selectedCategory = categories.find(cat => cat._id === categoryId);
    if (selectedCategory && selectedCategory.subCategories) {
      return selectedCategory.subCategories.map(subCategory => ({ value: subCategory, label: subCategory }));
    }
    return [];
  };

  useEffect(() => {
    if (brand) {
      const formattedCategories = Array.isArray(brand.categories)
        ? brand.categories.map(cat => ({
          category: cat.category?._id || cat.category || '',
          subCategory: cat.subCategory || ''
        }))
        : [{ category: brand.category?._id || brand.category || '', subCategory: brand.subCategory || '' }];

      const newFormData = {
        ...brand,
        categories: formattedCategories,
        country: brand.country?._id || (Array.isArray(brand.countries) && brand.countries.length > 0 ? brand.countries[0]._id : '') || brand.country || '',
      };
      setFormData(newFormData);
      setLogoPreview(brand.logo || '');
      fetchLogoFromAPI(brand.name);
      console.log('Brand data:', brand);
      console.log('New form data:', newFormData);
      console.log('Formatted categories:', formattedCategories);
    }
  }, [brand]);

  useEffect(() => {
  setFilteredUsers(users);
}, [users]);

  const handleSocialMediaChange = (e) => {
    setSelectedSocialMedia(e.target.value);
  };

  const addSocialMedia = () => {
    if (selectedSocialMedia && !formData.socialMedia.find(sm => sm.platform === selectedSocialMedia)) {
      setFormData(prev => ({
        ...prev,
        socialMedia: [...prev.socialMedia, { platform: selectedSocialMedia, url: '' }]
      }));
      setSelectedSocialMedia('');
    }
  };

  const updateSocialMediaUrl = (platform, url) => {
    setFormData(prev => ({
      ...prev,
      socialMedia: prev.socialMedia.map(sm =>
        sm.platform === platform ? { ...sm, url } : sm
      )
    }));
  };

  const removeSocialMedia = (platform) => {
    setFormData(prev => ({
      ...prev,
      socialMedia: prev.socialMedia.filter(sm => sm.platform !== platform)
    }));
  };

  useEffect(() => {
    if (formData.categories.length === 0) {
      setFormData(prev => ({
        ...prev,
        categories: [{ category: '', subCategory: '' }]
      }));
    }
  }, [formData.categories]);

  useEffect(() => {
    console.log('Current formData:', formData);
  }, [formData]);

  const inputClasses = "bg-black text-[#d3d3d3] border-[#282828] border-[3px] placeholder-[#d3d3d3]";

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!formData) {
    return <div>No data available</div>;
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="flex space-x-4">
        <div className="w-2/3 space-y-2">
          <Label htmlFor="name" className="text-white">Brand Name</Label>
          <Input
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter brand name"
            required
            className={inputClasses}
          />
          {logoOptions.length > 0 && (
            <div>
              <Label className="text-white">Logo Options</Label>
              <div className="grid grid-cols-5 gap-2">
                {logoOptions.slice(0, 10).map((logo, index) => (
                  <div
                    key={index}
                    className="w-full h-20 rounded-lg border-2 border-black overflow-hidden flex items-center justify-center cursor-pointer"
                    onClick={() => {
                      setLogoPreview(logo);
                      setFormData(prev => ({ ...prev, logo }));
                    }}
                  >
                    <img src={logo} alt={`Logo option ${index + 1}`} className="max-w-full max-h-full object-contain" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="w-1/3">
          <Label className="text-white">Logo Preview</Label>
          <div className="relative">
            <div className="border-2 border-black rounded-full p-1">
              <div className="w-32 h-32 rounded-full overflow-hidden flex items-center justify-center bg-gray-200">
                {logoPreview ? (
                  <img src={logoPreview} alt="Logo preview" className="max-w-full max-h-full object-contain" />
                ) : (
                  <p className="text-gray-500 text-sm">No logo</p>
                )}
              </div>
            </div>
            {logoPreview && (
              <button
                type="button"
                className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none"
                onClick={() => {
                  setLogoPreview(null);
                  setFormData(prev => ({ ...prev, logo: null }));
                }}
              >
                <span className="sr-only">Remove logo</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <Label className="text-white">Upload Logo</Label>
        <ImageUpload onImageUpload={handleImageUpload} />
      </div>

      <div className="space-y-2">
        <Label htmlFor="description" className="text-white">Brand Description</Label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          placeholder="Enter brand description"
          className={`w-full p-2 rounded ${inputClasses}`}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="activeDays" className="text-white">Active Days</Label>
        <Input
          id="activeDays"
          name="activeDays"
          type="number"
          value={formData.activeDays}
          onChange={handleChange}
          placeholder="Enter number of active days (-1 for unlimited)"
          className={inputClasses}
        />
        <p className="text-sm text-gray-400">Enter -1 for unlimited active days</p>
      </div>

      <div className="space-y-2">
        <Label htmlFor="frequency" className="text-white">Appearance Frequency</Label>
        <Input
          id="frequency"
          type="number"
          name="frequency"
          value={formData.frequency}
          onChange={(e) => setFormData(prev => ({ ...prev, frequency: parseFloat(e.target.value) }))}
          placeholder="Enter frequency (0-1)"
          min="0"
          max="1"
          step="0.1"
          className={inputClasses}
        />
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="categories" className="text-white">Categories</Label>
          {formData.categories.map((cat, index) => (
            <div key={index} className="flex space-x-2">
              <Select
                value={cat.category || ''}
                onValueChange={(value) => {
                  const newCategories = [...formData.categories];
                  newCategories[index] = { category: value, subCategory: '' };
                  setFormData(prev => ({ ...prev, categories: newCategories }));
                  console.log('Category changed:', value);
                }}
              >
                <SelectTrigger className={`w-full ${inputClasses}`}>
                  <SelectValue placeholder="Select Category">
                    {categories.find(c => c._id === cat.category)?.name || "Select Category"}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent className="bg-[#0f1014] text-[#d3d3d3] z-[100]">
                  {categories.map(category => (
                    <SelectItem key={category._id} value={category._id}>{category.name}</SelectItem>
                  ))}
                </SelectContent>
              </Select>

              <Select
                value={cat.subCategory || ''}
                onValueChange={(value) => {
                  const newCategories = [...formData.categories];
                  newCategories[index] = { ...newCategories[index], subCategory: value };
                  setFormData(prev => ({ ...prev, categories: newCategories }));
                  console.log('Subcategory changed:', value);
                }}
              >
                <SelectTrigger className={`w-full ${inputClasses}`}>
                  <SelectValue placeholder="Select Sub-Category">
                    {cat.subCategory || "Select Sub-Category"}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent className="bg-[#0f1014] text-[#d3d3d3] z-[100]">
                  {getSubCategoryOptions(cat.category).map(option => (
                    <SelectItem key={option.value} value={option.value}>{option.label}</SelectItem>
                  ))}
                </SelectContent>
              </Select>

              {index > 0 && (
                <Button
                  type="button"
                  onClick={() => {
                    const newCategories = formData.categories.filter((_, i) => i !== index);
                    setFormData(prev => ({ ...prev, categories: newCategories }));
                    console.log('Category removed');
                  }}
                  className="bg-red-500 text-white hover:bg-red-600 rounded-full"
                >
                  <X size={16} />
                </Button>
              )}
            </div>
          ))}
          <Button
            type="button"
            onClick={() => {
              setFormData(prev => ({
                ...prev,
                categories: [...prev.categories, { category: '', subCategory: '' }]
              }));
              console.log('New category added');
            }}
            className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4 mt-2"
          >
            Add Category
          </Button>
        </div>
      </div>

      <div className="space-y-4">
        <div className="flex items-center space-x-2">
          <Label htmlFor="global" className="text-white">Global Brand</Label>
          <input
            type="checkbox"
            id="global"
            checked={formData.global}
            onChange={(e) => setFormData(prev => ({ ...prev, global: e.target.checked }))}
            className="form-checkbox h-5 w-5 text-[#e0ff89] bg-black border-[#282828] rounded"
          />
        </div>

        <div className="space-y-2">
          <Label className="text-white">Countries</Label>
          <MultiSelect
            options={countries}
            value={formData.countries}
            onChange={handleCountriesChange}
            labelField="name"
            valueField="_id"
            placeholder="Select countries"
            onRemove={(removedItem) => {
              const updatedCountries = formData.countries.filter(country => country !== removedItem);
              handleCountriesChange(updatedCountries);
            }}
            classNames={{
              container: `${inputClasses}`,
              menu: 'bg-[#0f1014] text-[#d3d3d3]',
              option: 'hover:bg-[#15171c]',
              multiValue: 'bg-[#15171c] text-white',
              multiValueLabel: 'text-white',
              multiValueRemove: 'text-white hover:bg-[#0f1014]',
            }}
          />
        </div>

        console.log('Current owner value:', formData.owner);
        <div className="space-y-2">
          <Label htmlFor="owner" className="text-white">Brand Owner</Label>
          <Select
            value={formData.owner || ''}
            onValueChange={(value) => {
              console.log('Owner selection changed:', value);
              setFormData(prev => {
                const newData = { ...prev, owner: value };
                console.log('New form data after owner change:', newData);
                return newData;
              });
            }}
          >
            <SelectTrigger className={`w-full ${inputClasses}`}>
              <SelectValue>
                {formData.owner === 'admin'
                  ? 'Admin'
                  : users.find(u => u._id === formData.owner)?.username
                  || users.find(u => u._id === formData.owner)?.telegramUsername
                  || 'Select Owner'}
              </SelectValue>
            </SelectTrigger>
            <SelectContent className="bg-[#0f1014] text-[#d3d3d3] z-[100]">
              <div className="p-2">
                <input
                  type="text"
                  placeholder="Search users..."
                  value={searchQuery}
                  onChange={(e) => {
                    const query = e.target.value.toLowerCase();
                    setSearchQuery(query);
                    const filtered = users.filter(user =>
                      (user.username && user.username.toLowerCase().includes(query)) ||
                      (user.telegramUsername && user.telegramUsername.toLowerCase().includes(query))
                    );
                    setFilteredUsers(filtered);
                  }}
                  className="w-full p-2 mb-2 bg-[#0f1014] text-[#d3d3d3] border border-[#282828] rounded"
                />
              </div>
              <div className="max-h-48 overflow-y-auto">
                <SelectItem value="admin">Admin</SelectItem>
                {filteredUsers.map(user => (
                  <SelectItem key={user._id} value={user._id}>
                    {user.username || user.telegramUsername || 'Unnamed User'}
                  </SelectItem>
                ))}
              </div>
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="website" className="text-white">Website</Label>
          <Input
            id="website"
            name="website"
            value={formData.website || ''}
            onChange={handleChange}
            placeholder="Enter website URL"
            className={inputClasses}
          />
        </div>

        <div className="space-y-2">
          <Label className="text-white">Social Media</Label>
          <div className="flex space-x-2">
            <select
              value={selectedSocialMedia}
              onChange={(e) => setSelectedSocialMedia(e.target.value)}
              className={`w-full ${inputClasses} p-2 rounded`}
              style={{ maxHeight: '200px', overflowY: 'auto' }}
            >
              <option value="">Select Social Media</option>
              {Object.keys(socialIcons).map(platform => (
                <option key={platform} value={platform}>{platform}</option>
              ))}
            </select>
            <Button
              type="button"
              onClick={addSocialMedia}
              className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
              disabled={!selectedSocialMedia}
            >
              Add
            </Button>
          </div>
          <div className="space-y-2 mt-2">
            {formData.socialMedia.map(sm => {
              const Icon = socialIcons[sm.platform];
              return (
                <div key={sm.platform} className="flex items-center space-x-2">
                  <div className="w-8 h-8 rounded-full bg-[#e0ff89] flex items-center justify-center">
                    <Icon className="text-black" />
                  </div>
                  <Input
                    value={sm.url}
                    onChange={(e) => updateSocialMediaUrl(sm.platform, e.target.value)}
                    placeholder={`Enter ${sm.platform} URL`}
                    className={`flex-grow ${inputClasses}`}
                  />
                  <Button
                    type="button"
                    onClick={() => removeSocialMedia(sm.platform)}
                    className="p-2 bg-black text-white rounded-full hover:bg-red-600"
                  >
                    <X size={16} />
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-6 space-x-2">
        <Button
          type="button"
          onClick={onCancel}
          variant="outline"
          className="bg-black text-white border-[#282828] hover:bg-white hover:text-black transition-colors duration-200 rounded-[30px] py-1 px-4"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="bg-[#e0ff89] text-black hover:bg-[#c0d866] rounded-[30px] py-1 px-4"
        >
          Save Brand
        </Button>
      </div>
    </form>
  );
};

export default BrandForm;